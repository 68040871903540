const pages = {
  base: '/',
  terms: '/pages/terms-and-conditions',
  salesTerms: '/pages/sale-terms-and-conditions',
  privacyPolicy: '/pages/privacy-policy',
  ordering: '/pages/faqs#faq-orders',
  orderingShipping: '/pages/faqs#faq-shipping-+-delivery',
  productCare: '/pages/faqs#faq-rug-care',
  accessibility: '/pages/accessibility',
  about: '/pages/about',
  aboutBen: '/pages/about-ben',
  sale: '/pages/sale',
  blackFridaySale: '/pages/black-friday',
  shopByRoom: '/shop-by-room',
  faq: '/pages/faqs',
  contact: '/pages/contact',
  facebook: 'https://www.facebook.com/bensoleimanihome',
  pinterest: 'https://www.pinterest.com/bensoleimani',
  instagram: 'https://www.instagram.com/bensoleimani',
  linkedIn: 'https://www.linkedin.com/company/ben-soleimani',
  newsletter: '/pages/newsletter',
  designServices: '/pages/design-services',
  needAssistance: '/pages/need-assistance',
  productDetail: '/products',
  search: '/search',
  antiqueRugs: '/collections/antique-rugs',
  coloursByBen: '/pages/colours-by-ben',
  basePages: '/pages',
  rugsSizeGuide: '/pages/size-guide',
  showroom: '/pages/showroom',
  antiquesShowroom: '/pages/antiques-showroom',
  nycShowroom: '/pages/nyc-showroom',
  tradeProgram: '/pages/trade-program',
  careers: '/pages/careers',
  giftCard: '/products/gift-card',
  bespokeServices: '/pages/bespoke-services',
  rugFinder: '/pages/rug-finder',
  tryInHome: '/pages/try-in-home',
  fabricByTheYard: '/pages/fabric-by-the-yard',
  catalog: '/collections/fall-winter-2024-catalog',
  rugsourcebook: '/pages/rugsourcebook',
  productsourcebook: '/pages/productsourcebook',
  digitalsourcebook: '/pages/digitalsourcebook',
}

const collections = {
  base: '/collections',
  sale: '/collections/sale',
  newArrivals: '/collections/new-arrivals',
  ocean: '/collections/ocean',
  living: '/collections/living-room',
  horses: '/collections/horses',
  nature: '/collections/nature',
  artOutdoor: '/collections/art-outdoor/',
  color: '/collections/color/',
  fabrics: '/collections/fabrics/',
  furniture: '/collections/furniture/',
  rugs: '/collections/rugs',
  customQuickShip: '/collections/custom-quick-ship',
  performance: '/collections/performance',
  flatweave: '/collections/flatweave-rugs',
  hide: '/collections/hide-rugs',
  moroccan: '/collections/moroccan-rugs',
  textured: '/collections/textured-rugs',
  indoorOutdoor: '/collections/indoor-outdoor-rugs',
  traditional: '/collections/traditional-rugs',
  contemporary: '/collections/contemporary-rugs',
  dining: '/collections/dining-room',
  bedroom: '/collections/bedroom',
  lighting: '/collections/lighting',
  decor: '/collections/decor',
  textiles: '/collections/textiles',
  outdoor: '/collections/outdoor',
  office: '/collections/home-office',
  rugs6x9: '/collections/6x9-rugs',
  rugs8x10: '/collections/8x10-rugs',
  rugs9x12: '/collections/9x12-rugs',
  rugs10x14: '/collections/10x14-rugs',
  rugs12x15: '/collections/12x15-rugs',
  rugs12x18: '/collections/12x18-rugs',
  runners: '/collections/runners',
  oversizedRugs: '/collections/oversized-rugs',
  circular: '/collections/circular',
  rugsWhite: '/collections/white',
  rugsWhiteOff: '/collections/off-white',
  rugsNatural: '/collections/natural',
  taupeRugs: '/collections/taupe-rugs',
  rugsGrey: '/collections/grey',
  rugsBrown: '/collections/brown',
  rugsBlack: '/collections/black',
  rugsBlue: '/collections/indigo',
  rust: '/collections/rust',
  rugsGold: '/collections/gold',
  rugsGreen: '/collections/emerald',
  chromaRugCollection: '/collections/the-chroma-rug-collection',
  luxeCollection: '/collections/luxe-collection',
  classicRugs: '/collections/classic-rugs',
  jute: '/collections/jute',
  sofas: '/collections/sofas',
  loungeChairs: '/collections/lounge-chairs',
  benchesOttomans: '/collections/benches-and-ottomans',
  coffeeSideTables: '/collections/coffee-tables',
  sideTables: '/collections/side-tables',
  consoleTablesCredenzas: '/collections/console-tables-and-credenzas',
  entryTables: '/collections/entry-tables',
  homeOffice: '/collections/home-office',
  desks: '/collections/desks',
  deskChairs: '/collections/desk-chairs',
  diningTables: '/collections/dining-tables',
  diningChairs: '/collections/dining-chairs',
  counterAndBarStools: '/collections/counter-and-bar-stools',
  benches: '/collections/benches',
  beds: '/collections/beds',
  mattresses: '/collections/mattresses',
  bedding: '/collections/bedding',
  dressersAndNightstands: '/collections/dressers-and-nightstands',
  pillows: '/collections/pillows',
  throws: '/collections/throws',
  tableLamps: '/collections/table-lamps',
  floorLamps: '/collections/floor-lamps',
  chandeliers: '/collections/chandeliers',
  sconces: '/collections/sconces',
  art: '/collections/art',
  mirrors: '/collections/mirrors',
  decorativeObjects: '/collections/decorative-objects',
  games: '/collections/games',
  pictureFrames: '/collections/picture-frames',
  candlesAndCandelabras: '/collections/candles-and-candelabras',
  assoulineBooks: '/collections/assouline-books',
  linenFabric: '/collections/linen-fabric',
  performanceFabric: '/collections/performance-fabric',
  outdoorFabric: '/collections/outdoor-fabric',
  distressedTexturedWeave: '/collections/distressed-textured-weave',
  wovenBasketweave: '/collections/woven-basketweave',
  velvet: '/collections/velvet',
  alpaca: '/collections/alpaca',
  alpacaTweed: '/collections/alpaca-tweed',
  mohairBoucle: '/collections/mohair-boucle',
  outdoorRugs: '/collections/outdoor-rugs',
  outdoorFurniture: '/collections/outdoor-furniture',
  collaborationDF: 'collections/douglas-friedman-hyperopia',
  gift: '/collections/gift-guide',
}

const account = {
  signUp: '/sign-up',
  signIn: '/sign-in',
  account: '/account',
  accountDashboard: '/account/dashboard',
  accountProfile: '/account/profile',
  accountPersonalData: '/account/personal-data',
  accountOrders: '/account/orders',
  accountAddresses: '/account/addresses',
  accountAddressesAdd: '/account/addresses/add',
  accountAddressesEdit: '/account/addresses/edit/',
  accountPaymentMethods: '/account/payment-methods',
  accountPaymentMethodsAdd: '/account/payment-methods/add',
  accountPortfolios: '/account/portfolios',
  accountPortfoliosAdd: '/account/portfolios/add',
  accountPortfoliosEdit: '/account/portfolios/edit',
  accountPortfoliosDetail: '/account/portfolios/detail',
  accountPortfoliosShared: '/account/shared/portfolios',
  accountSaveForLater: '/account/save-for-later',
  forgotPassword: '/forgot-password',
  logout: '/logout',
}

const purchase = {
  cart: '/cart',
  checkout: '/checkout',
  payment: '/payment',
  shipping: '/shipping',
}

const blog = {
  press: '/blogs/press',
  journal: '/blogs/journal',
}

export default {
  pages,
  collections,
  account,
  purchase,
  blog,
}
